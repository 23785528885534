import React from 'react';
import styled from 'styled-components';

// You'll need to replace this with the actual path to your logo file
import logoImage from '../assets/logo.png';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 32px;
`;

const EastSussexLogo = () => {
  return (
    <LogoWrapper>
      <LogoImg src={logoImage} alt="East Sussex County Council Logo" />
    </LogoWrapper>
  );
};

export default EastSussexLogo;